.presentation-img {
    width: 100%;
    height: auto;
}

.presentation-section-inner {
    background-color: $primary;
    .presentation-section-inner-content {
        @include media-breakpoint-up(xl) {
            columns: 2;
            column-gap: 2rem;
        }
    }
}
.presentation-section-inner-title {
    text-transform: none;
}