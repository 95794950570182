/**
 * GENERAL
 * MODULES
 * MEDIA QUERIES
 */





/* GENERAL
---------------------------------------- */
.page-introduction {
    padding: 1rem 0;
    @include media-breakpoint-up(lg) {
        padding: 1rem 0 2rem;
    }
}
.page-introduction-home {
    padding: 2rem;
    @include media-breakpoint-up(lg) {
        padding: 4rem 0;
    }
    @include media-breakpoint-up(xl) {
        padding: 5rem 0;
    }
}

.type-pate-title {
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    text-transform: none;
    padding-bottom: 3rem !important;
}

.type-pate-img {
    height: 150px;
    width: 180px;
}
.wysiwyg-img-second-section-address {
    color: $bodyBackgroundColor;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
}
.wysiwyg-img-second-section-tel {
    color: $bodyBackgroundColor;
    text-decoration: none;
    font-size: 30px;
    font-weight: 600;
}
.grid-default {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(14, 1fr);
    grid-template-rows: repeat(2, 35vh);
    .grid-default-item {
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .grid-default-item-1 {
        grid-column: 1/15;
        @include media-breakpoint-up(lg) {
            grid-column: 1/7;
        }
    }
    .grid-default-item-2 {
        grid-column: 1/15;
        @include media-breakpoint-up(lg) {
            grid-column: 7/15;
        }
    }
    .grid-default-item-3 {
        grid-column: 1/15;
        @include media-breakpoint-up(lg) {
            grid-column: 1/7;
        }
    }
    .grid-default-item-4 {
        grid-column: 1/15;
        @include media-breakpoint-up(lg) {
            grid-column: 7/11;
        }
    }
    .grid-default-item-5 {
        grid-column: 1/15;
        @include media-breakpoint-up(lg) {
            grid-column: 11/15;
        }
    }
}
.offcanvas {
    background-color: $bodyBackgroundColor;
}

.liste-nos-boutiques {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 48px;
    .nos-produits-item {
        flex-basis: calc((100% - 192px) / 5);
    }

    @media only screen and (max-width: 1199px) {
        gap: 24px;
        .nos-produits-item {
            flex-basis: calc((100% - 48px) / 3);
        }
    }
    @media only screen and (max-width: 991px) {
        gap: 16px;
        .nos-produits-item {
            flex-basis: calc((100% - 32px) / 3);
        }
    }
    @media only screen and (max-width: 767px) {
        .nos-produits-item {
            flex-basis: calc((100% - 16px) / 2);
        }
    }
    @media only screen and (max-width: 575px) {
        .nos-produits-item {
            flex-basis: 100%;
        }
    }
}





/* MODULES
---------------------------------------- */
.modules-wrapper {
    position: relative;
    margin-top: 25px;
    &::before {
        position: absolute;
        top: -100px;
        right: 0;
        width: 60%;
        padding-top: 62%;
        background: url('../images/A.svg') no-repeat center;
        background-size: contain;
        content: '';
    }
}
.module {
    position: relative;
    margin: 92px 0;
    &:first-child {
        margin-top: 0;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.module-image-text {
    &.img-left .illus-wrapper {
        margin-left: 0;
    }
    &.img-right .illus-wrapper {
        margin-right: 0;
        order: 5;
    }
    .cols-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .illus-wrapper, .txt {
        flex-basis: calc(50% - 8px);
    }
    .illus-wrapper {
        margin: 0 16px;
    }
    .illus {
        display: block;
    }
    .txt {
        flex-grow: 1;
    }
}
.module-text-highlighted {
    margin: 125px 0;
    padding: 140px 0;
    background: $primary url('../images/bg.png') no-repeat center;
    background-size: cover;
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .3;
        background-color: $primary;
        content: '';
    }
    &:last-child {
        margin-bottom: 0;
    }
    .container {
        position: relative;
    }
    .txt {
        // font-size: 30px;
        // font-weight: 600;
        color: $bodyBackgroundColor;
    }
    h1, h2, h3, h4, h5, h6 {
        color: inherit;
    }
    .title-list-logos {
        color: $bodyBackgroundColor;
    }
    .list-logos-wrapper {
        padding: 70px 40px;
        background-color: #fff;
    }
}
.title-list-logos {
    margin-top: 24px;
    margin-bottom: 16px;
    padding: 0;
    font-family: Albra, Montserrat, Arial, sans-serif;
    font-size: 35px;
    line-height: 1;
    font-weight: bold;
    text-transform: none;
}
.list-logos-wrapper {
    margin-top: 55px;
}
.list-logos {
    display: flex;
    margin: 0 -30px;
    padding: 0;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
    row-gap: 30px;
    li {
        margin: 0 30px;
        flex-basis: calc(100% / 6 - 60px);
    }
}





/* MEDIA QUERIES
---------------------------------------- */
@media only screen and (max-width: 991px) {
    .list-logos li {
        flex-basis: calc(100% / 3 - 60px);
    }
}



@media only screen and (max-width: 960px) {
    .module {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .module-image-text {
        .cols-wrapper {
            display: block;
        }
        .illus-wrapper {
            margin: 0 auto 30px;
        }
    }
    .module-text-highlighted {
        margin-top: 75px;
        margin-bottom: 75px;
        padding-top: 75px;
        padding-bottom: 75px;
    }
}



@media only screen and (max-width: 600px) {
    .module-text-highlighted .list-logos-wrapper {
        padding: 40px 20px;
    }
    .list-logos {
        display: flex;
        margin: 0 -20px;
        row-gap: 20px;
        li {
            margin: 0 20px;
            flex-basis: calc(100% / 2 - 40px);
        }
    }
}
