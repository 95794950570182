.page-template-contact .address hr {
    max-width: 50%;
    margin: 2.45rem 0;
}
.contact-map {
    height: 400px;
    @include media-breakpoint-up(xl) {
        height: 733px;
    }
}
.contact-h4 {
    text-transform: none;
    padding-bottom: 1rem;
    @include media-breakpoint-up(lg) {
        margin-bottom: 2rem;
    }
}
.contact-main-title {
    text-align: center;
    text-transform: none;
    padding-bottom: 3rem;
}
.contact-title {
    font-size: 35px;
    font-weight: 700;
    position: relative;
    text-transform: none;
    color: $secondary;
    padding-bottom: 0.5em;
    margin-bottom: 0.75em;
    font-family: Albra, Montserrat, Arial, sans-serif;
    &::after {
        content: '';
        height: 7px;
        width: 70px;
        background-color: $primary;
        bottom: 0;
        left: 0;
        position: absolute;
    }
}
.contact-cabinets .contact-cabinet:not(:last-child) {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 42px;
        height: 3px;
        background-color: $primary;
    }
}
.contact-cabinet {
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
    }
    @include media-breakpoint-up(xxl) {
        padding-bottom: 3rem;
        margin-bottom: 3rem;
    }
    .contact-cabinet-ville {
        font-size: 30px;
        text-transform: uppercase;
        color: #b4b4b4;
    }
    .contact-cabinet-address {
        margin-bottom: 0;
        padding-bottom: .75rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include media-breakpoint-up(lg) {
            padding-bottom: 1.5rem;
        }
        span.contact-cabinet-address-street {
            font-size: 19px;
            font-weight: 500;
        }
        span.contact-cabinet-address-city {
            font-size: 19px;
            font-weight: 700;
        }
    }
    a.contact-cabinet-tel {
        font-weight: 900;
        font-size: 35px;
        text-decoration: none;
        line-height: 1;
    }
    .contact-cabinet-horaires-title {
        font-size: 19px;
        font-weight: 700;
        padding-top: .75rem;
        @include media-breakpoint-up(lg) {
            padding-top: 1.5rem;
        }
    }
    .contact-cabinet-horaires {
        font-size: 19px;
        font-weight: 500;
    }

}

.container-pin {
  max-width: rem(40px);
  max-height: rem(40px);
  padding: rem(5px) rem(5px);
  background-color: $primary;
  border-radius: 50% 50% 50% 0;
  transform: translateY(-85%) translateX(-20%) rotate(-45deg);
  position: relative;
  box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
}

.img-pin {
  transform: rotate(45deg);
  width: 20px;
}
