$primary: #b91f27;
$secondary: #0D223F;
$bodyBackgroundColor: #fdfbf4;
$thirdColor: #fcf7e7;
$defaultTransition: all .35s ease-in-out;
$textColor: #000;
$thirdColor: #fcf7e7;
$btn-font-weight: 700;
$btn-padding-y: .15rem;
$btn-padding-x: 1.35rem;
$btn-border-radius: 0rem;
$btn-border-radius-sm: 0rem;
$btn-border-radius-lg: 0rem;

$btn-hover-bg-shade-amount: 50%;

$input-btn-padding-y: .65rem;
$input-btn-padding-x: 1.5rem;

$grid-gutter-width: 1.5rem;
$spacer: 1rem !default;
$gutters: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
  9: $spacer * 8,
  10: $spacer * 10,
);

$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
  9: $spacer * 8,
  10: $spacer * 10,
);