.page-header {
    padding-bottom: 3rem;
    @include media-breakpoint-up(lg) {
        padding-bottom: 3.25rem;
    }
    .page-header-inner {
        padding: 8rem 6rem 7.5rem 6rem;
        background-size: cover;
        position: relative;
        @media only screen and (max-width: 600px) {
            padding: 6rem 3rem 5.5rem 3rem;
        }
        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: .5;
            z-index: 1;
        }
        .page-header-inner-svg-outer {
            position: absolute;
            bottom: 0;
            right: 3.5%;
            transform: translateY(calc(100% + 20px));
            opacity: 0;
            visibility: hidden;
            img, svg {
                width: 110px;
                height: auto;
                transition: $defaultTransition;
            }
            @include media-breakpoint-up(xl) {
                opacity: 1;
                visibility: visible;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 1px;
                height: 100px;
                background-color: $primary;
                transform: translateY(calc(100% + 2rem));
                opacity: 0;
                visibility: hidden;
                @media screen and (min-width: 2000px) {
                    opacity: 1;
                    visibility: visible;
                }
            }
            &:hover {
                img, svg {
                    transform: rotate(15deg);
                }
            }
        }
        & > img {
            max-width: 100%;
            display: block;
            margin: 0 auto;
            position: relative;
            z-index: 2;
        }
        h1 {
            background-color: $primary;
            padding: .8em 2em;
            width: 70%;
            text-transform: none;
            font-weight: 700;
            position: absolute;
            text-align: center;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) translateY(50%);
            z-index: 3;
            color: $bodyBackgroundColor;
            font-size: 25px;
            font-family: Albra, Montserrat, Arial, sans-serif;
            @include media-breakpoint-up(md) {
                font-size: 35px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 45px;
                width: 60%;
            }
            @media screen and (max-width: 600px) {
                padding-right: .5em;
                padding-left: .5em;
            }
        }
    }
}
.breadcrumbs {
    padding: 4rem 0 2rem;
}
