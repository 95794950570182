/**
 * GENERAL
 */

$size-default:          16px;
$line-height-default:   1.2;
$letter-spacing-default: -.04em;


// Container
$container-width:           1430px;
$container-padding:         5vw;


// REM
// ----------------------------------------
@function rem($px_value, $base: $size-default) {
  @if $px_value == 0 or type-of($px_value) != 'number' {
    @return $px_value;
  }
  @return calc($px_value / ($base / 1rem));
}

// CONTAINER
// ----------------------------------------
@mixin container($width: $container-width, $padding: $container-padding) {
  width: 100%;
  max-width: calc(#{$padding} * 2 + #{$width});
  margin-right: auto;
  margin-left: auto;
  padding-right: $padding;
  padding-left: $padding;
}
// FAKE FLEX COL
// ----------------------------------------
@mixin animation-underline($height-border: 1px) {
  background: linear-gradient(to right, currentColor, currentColor) no-repeat 100% 100%;
  background-size: 0 rem($height-border);
  &:not(:active) {
    transition: color .3s ease, background-size .3s ease !important;
  }
  @include hover {
    background-position: 0 100%;
    background-size: 100% rem($height-border);
  }
}


// HOVER FOCUS NOT ACTIVE
// ----------------------------------------
@mixin hover() {
  &:hover, &:focus {
    &:not(:active) {
      @content;
    }
  }
}

/* GENERAL
---------------------------------------- */
.no-cookies-wrapper {
    margin: rem(35px) auto;
    @include container(rem(750px));
    .container & {
        @include container(rem(750px), 0px);
    }
}
.no-cookies {
    position: relative;
    width: 100%;
    padding-top: 55%;
    background-color: #e0e0e0;
    .no-cookies-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 25%;
        max-width: rem(330px);
        transform: translate(-50%, -50%);
        svg {
            display: block;
        }
    }
    .no-cookies-txt {
        position: absolute;
        bottom: rem(10px);
        left: 0;
        width: 100%;
        text-align: center;
        font-size: rem(14px);
        line-height: $line-height-default;
        letter-spacing: $letter-spacing-default;
        font-weight: 400;
        a, button {
            @include animation-underline();
            background-position: 0 100%;
            background-size: 100% 1px;
            border: 0;
            @include hover {
                background-position: 100% 100%;
                background-size: 0 1px;
                color: $primary;
            }
        }
    }
}
