/**
 * GENERAL
 * MEDIA QUERIES
 */





/* GENERAL
---------------------------------------- */
.banner-boutique {
    position: relative;
    margin-bottom: 100px;
    .cols-wrapper {
        display: flex;
        overflow: hidden;
        justify-content: flex-start;
        align-items: stretch;
    }
    .col-left {
        position: relative;
        padding-top: 110px;
        z-index: 0;
        flex-basis: 44%;
        &::before {
            position: absolute;
            top: 40%;
            left: 73%;
            width: 395px;
            max-width: 48%;
            height: 443px;
            z-index: -1;
            background: url('../images/fourchette-haut-page.svg') no-repeat center;
            background-size: contain;
            content: '';
            transform: translate(-50%, -50%);
        }
    }
    .col-right {
        margin-left: 6%;
        flex-basis: 50%;
    }
    .title {
        max-width: 580px;
        margin: 0 0 0 auto;
        font-family: Albra, Montserrat, Arial, sans-serif;
        font-size: 75px;
        line-height: 1;
        font-weight: bold;
        color: $primary;
    }
    .sub-title {
        position: relative;
        max-width: 441px;
        margin: 35px 0 0 auto;
        font-size: 25px;
        line-height: 1.32;
        font-weight: 800;
        &::before {
            position: absolute;
            top: 25px;
            right: calc(100% + 3.64vw);
            width: 100%;
            height: 1px;
            background-color: $primary;
            content: '';
        }
        .small {
            display: block;
            font-size: .88em;
            font-weight: 400;
        }
    }
    .txt {
        max-width: 441px;
        margin: 64px 0 0 auto;
        line-height: 1.45;
    }
    .illus-wrapper {
        position: relative;
        &::before {
            position: absolute;
            top: 20.68%;
            left: 0;
            width: 70px;
            height: 25px;
            background-color: $primary;
            content: '';
            transform: translateX(-50%);
        }
    }
    .illus {
        display: block;
        max-width: 100%;
    }
}





/* MEDIA QUERIES
---------------------------------------- */
@media only screen and (max-width: 1400px) {
    .banner-boutique .title {
        padding-right: 0;
        font-size: 50px;
    }
}



@media only screen and (max-width: 960px) {
    .banner-boutique {
        margin-bottom: 75px;
        .cols-wrapper {
            display: block;
        }
        .col-left {
            padding-top: 50px;
        }
        .col-right {
            margin-top: 50px;
            margin-left: 0;
        }
        .title {
            margin: 0;
        }
        .sub-title, .txt {
            max-width: 650px;
        }
        .txt {
            margin-top: 35px;
        }
        .illus-wrapper::before {
            display: none;
        }
    }
}



@media only screen and (max-width: 600px) {
    .banner-boutique {
        .title {
            font-size: 40px;
        }
        .sub-title, .txt {
            margin-top: 20px;
        }
        .sub-title {
            font-size: 20px;
        }
        .txt {
            font-size: 18px;
        }
    }
}
