/**
 * GENERAL
 * MEDIA QUERIES
 */





/* GENERAL
---------------------------------------- */
.post-type-archive .page-header-inner-svg-outer {
    display: none;
}
.list-posts {
    display: flex;
    margin: -70px -13px 75px;
    padding-left: 0;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    list-style-type: none;
    .item {
        margin-top: 80px;
        margin-right: 13px;
        margin-left: 13px;
        flex-basis: calc(100% / 3 - 26px);
    }
    .link:hover {
        .illus-wrapper::after {
            opacity: .7;
        }
        .title, .fake-link {
            color: $secondary;
        }
    }
    .link {
        display: block;
        color: #130234;
        text-decoration: none;
    }
    .illus-wrapper {
        position: relative;
        background-color: rgba(#000, .1);
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 5;
            background-color: $secondary;
            content: '';
            transition: all .3s ease;
        }
    }
    .illus {
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
        aspect-ratio: 1/0.83;
        object-fit: cover;
        object-position: center;
    }
    .desc {
        margin-top: 11px;
        align-self: center;
    }
    .category {
        margin-bottom: 12px;
        font-size: 15px;
        line-height: 1.2;
        font-weight: bold;
        color: $secondary;
        text-transform: uppercase;
    }
    .title {
        max-width: 577px;
        margin-bottom: 0;
        font-family: Albra, Montserrat, Arial, sans-serif;
        font-size: 45px;
        line-height: 1.1;
        font-weight: bold;
        color: $primary;
        transition: all .3s ease;
    }
    .txt {
        max-width: 523px;
        margin-top: 7px;
        font-size: 20px;
        line-height: 1.2;
        p {
            font-size: inherit;
            line-height: inherit;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .fake-link {
        display: block;
        margin-top: 16px;
        font-size: 15px;
        line-height: 1.2;
        font-weight: 600;
        text-transform: uppercase;
        transition: all .3s ease;
    }
    .date {
        display: block;
        margin-top: 20px;
        font-size: 16px;
        line-height: 1.2;
        font-weight: 300;
    }
}
.btn-more-posts-wrapper {
    margin-bottom: 50px;
    text-align: center;
}
.btn-more-posts {
    display: inline-block;
    padding: 5px;
    text-align: center;
    font-size: 15px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    color: $secondary;
    transition: all .3s ease;
    &:hover {
        color: $primary
    }
}





/* MEDIA QUERIES
---------------------------------------- */
@media only screen and (max-width: 1200px) {
}



@media only screen and (min-width: 1025px) {
    .list-posts {
        .item {
            &:nth-child(14n+1), &:nth-child(14n+8) {
                flex-basis: 100%;
                .link {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
                .illus-wrapper {
                    margin: 0 26px;
                    flex-basis: 61.11%;
                }
                .desc {
                    margin-top: 0;
                    flex-basis: calc(100% - 61.11% - 26px);
                }
                .category {
                    margin-bottom: 2px;
                }
                .txt {
                    margin-top: 25px;
                }
                .fake-link {
                    margin-top: 21px;
                }
                .date {
                    margin-top: 25px;
                }
            }
            &:first-child {
                .illus-wrapper {
                    margin-left: 0;
                }
                .illus {
                    aspect-ratio: 1/0.7;
                }
            }
            &:nth-child(7n+8) {
                .illus-wrapper {
                    margin-right: 0;
                    order: 5;
                }
                .illus {
                    aspect-ratio: 1/0.57;
                }
            }
        }
    }
}



@media only screen and (max-width: 1024px) {
    .list-posts {
        .item {
            flex-basis: calc(50% - 13px);
        }
        .link {
            display: block;
        }
        .title {
            font-size: 35px;
        }
    }
}



@media only screen and (max-width: 960px) {
}



@media only screen and (max-width: 600px) {
    .list-posts {
        .item {
            flex-basis: 100%;
        }
    }
}



@media only screen and (max-width: 480px) {
    .list-posts .title {
        font-size: 28px;
    }
}
