.module-list {
    margin-top: 50px;
    margin-bottom: 50px;
    .list {
        ul {
            overflow: hidden;
            padding: 0;
            li {
                position: relative;
                margin-top: 6px;
                padding-left: 20px;
                list-style-type: none;
                font-weight: bold;
                text-transform: uppercase;
                &::before {
                    position: absolute;
                    top: 6px;
                    left: 0;
                    width: 10px;
                    height: 10px;
                    border: 3px solid $primary;
                    border-bottom: 0;
                    border-left: 0;
                    content: '';
                    transform: rotate(45deg);
                }
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}
