.citation {
    background-color: $primary;
    background-size: cover;
    background-position: center;
    position: relative;

    h3 {
        text-align: center;
        padding-bottom: 2.5rem;
    }
    blockquote {
        text-align: center;
        color: $bodyBackgroundColor;
    }
    img.citation-portrait {
        width: 150px;
        height: 150px;
        border-radius: 50rem;
        object-fit: cover;
    }
    span.citation-firstname {
        font-size: 18px;
        font-weight: 700;
        color: $bodyBackgroundColor;
    }
    span.citation-lastname {
        font-size: 18px;
        font-weight: 700;
        color: $bodyBackgroundColor;
    }
    span.citation-poste {
        font-size: 12px;
        font-weight: 600;
        color: $bodyBackgroundColor;
        background-color: $secondary;
        text-transform: uppercase;
        padding: .5rem;
    }
}