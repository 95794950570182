// Nav top
.nd-top-menu {
    display: none;
    align-items: center;
    gap: 1rem;
    @include media-breakpoint-up(lg) {
        display: flex;
        transform: translateY(-1rem);
    }
    .nd-top-menu-search {
        position: relative;
        margin-left: 1rem;
        cursor: pointer;
        &::before {
            content: '';
            height: 4px;
            width: 4px;
            background-color: $secondary;
            border-radius: 100%;
            position: absolute;
            top: 50%;
            left: -1rem;
            transform: translateY(-50%);
        }
        i {
            transition: color .35s ease-in-out;
            color: $secondary;
        }
        &:hover {
            i {
                color: $primary;
            }
        }
    }
}
.nd-menu {
    display: none;
    @include media-breakpoint-up(lg) {
        display: flex;
    }
    & > div {
        & > ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            li {
                a {
                    color: $secondary;
                    text-transform: uppercase;
                    font-size: 13px;
                    font-weight: 600;
                    text-decoration: none;
                    transition: color .35s ease-in-out;
                    &:hover {
                        color: $primary;
                    }
                }
                &.current-menu-item {
                    a {
                        color: $primary;
                    }
                }
                &.menu-item-has-children {
                    position: relative;
                    ul.sub-menu {
                        position: absolute;
                        z-index: 5;
                        left: 50%;
                        transform: translateY(2rem) translateX(-50%);
                        top: 0;
                        background-color: $secondary;
                        list-style-type: none;
                        padding-left: 0;
                        margin-bottom: 0;
                        width: fit-content;
                        padding: 1rem 1.5rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        opacity: 0;
                        visibility: hidden;
                        transition: all .35s ease-in-out;
                        li {
                            a {
                                text-transform: uppercase;
                                font-size: 15px;
                                font-weight: 600;
                                white-space: nowrap;
                                color: $bodyBackgroundColor;
                                &:hover {
                                    color: $bodyBackgroundColor;
                                }
                            }
                            &.current-menu-item {
                                a {
                                    color: $bodyBackgroundColor;
                                }
                            }
                        }
                    }
                    &:hover {
                        ul.sub-menu {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

// Main menu
.nd-container {
    .nd-container-outer {
        padding: 1rem 0;
        @include media-breakpoint-up(md) {
            padding: 2rem 0;
        }
    }
    &.is-fixed {
        position: fixed !important;
        opacity: 0;
        visibility: hidden;
        top: -130px !important;
        left: 0;
        right: 0;
        z-index: 999 !important;
        background-color: #fff;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        transition: 0.35s top cubic-bezier(0.3, 0.73, 0.3, 0.74), 0.35s opacity cubic-bezier(0.3, 0.73, 0.3, 0.74), 0.35s visibility cubic-bezier(0.3, 0.73, 0.3, 0.74);
        &.slideDown {
            top: 0 !important;
            opacity: 1;
            visibility: visible;
        }
    }
}
.nd-logo-link {
    img, svg {
        height: 50px;
        max-width: inherit;
        width: auto;
        @include media-breakpoint-up(md) {
            height: 80px;
        }
    }
}

.nd-container-outer {
    border-bottom: 1px solid $primary;
}

.nm-btn {
    display: inline-block;
    border: 1px solid $primary;
    background-color: $bodyBackgroundColor;
    padding: 0.4rem .5rem;
    transition: all .35s ease-in-out;
    line-height: 1;
    @include media-breakpoint-up(lg) {
        display: none;
    }
    i {
        transition: all .35s ease-in-out;
        color: $primary;
    }
    &:hover {
        background-color: $primary;
        i {
            color: $bodyBackgroundColor;
        }
    }
}

.nd-menu-mobile {
    & > div {
        & > ul {
            list-style-type: none;
            padding-left: 0;
            li {
                a {
                    color: $primary;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-weight: 600;
                    text-decoration: none;
                    transition: color .35s ease-in-out;
                    &:hover {
                        color: $secondary;
                    }
                }
            }
            ul.sub-menu {
                list-style-type: none;
                padding-left: 1rem;
                li {
                    a {
                        text-transform: uppercase;
                        font-size: 16px;
                        font-weight: 600;
                        &:hover {
                            color: $secondary;
                        }
                    }
                }
            }
        }
    }
}

.nd-top-menu {
    ul {
        display: flex;
        align-items: center;
        gap: 1rem;
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
            a {
                font-size: 13px;
                font-weight: 500;
                color: $textColor;
                text-decoration: none;
                &.current-menu-item {
                    color: $primary;
                }
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

.nd-top-menu-social {
    li {
        a {
            i {
                font-size: 18px;
                transition-duration: $defaultTransition;
                color: $secondary;
            }
            &:hover {
                i {
                    color: $primary;
                }
            }
        }
    }
}



// Mobile
.nm-container {
    display: block;
    padding: 1rem 0;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.nm-logo-link {
    img, svg {
        height: auto;
        max-width: 180px;
        width: 180px;
    }
}

.btn.nd-top-menu-cta {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 14px;
    display: inline-flex;
    font-weight: 700;
    padding: 0.5rem 1.25rem;
    gap: 0.75rem;
    img, svg {
        width: auto;
        height: 20px;
    }
    span {
        color: $bodyBackgroundColor;
    }
    &.btn-primary:hover {
        background-color: $bodyBackgroundColor;
        svg * {
            fill: $primary;
        }
        span {
            color: $primary;
        }
    }
    &.btn-secondary:hover {
        background-color: $bodyBackgroundColor;
        svg * {
            fill: $secondary;
        }
        span {
            color: $secondary;
        }
    }
}

.custom-container {
    margin: 0 auto;
    padding: 0 12px;
    @include media-breakpoint-up(xxl) {
        width: 85%;
    }
    @media screen and (min-width: 1600px) {
        width: 95%;
    }
}
