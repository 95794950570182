.btn-primary, .btn-secondary {
    color: $bodyBackgroundColor;
    &:hover {
        color: $bodyBackgroundColor;
    }
}
.btn {
    padding: 1rem 2.5rem;
    color: $bodyBackgroundColor;
    font-size: 16px;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {

    }
}
.btn-outline-primary {
    color: $primary;
    &:hover {
        color: $bodyBackgroundColor;
        background-color: $primary;
    }
}

.btn-outline-secondary {
    color: $secondary;
    &:hover {
        color: $bodyBackgroundColor;
        background-color: $secondary;
    }
}
