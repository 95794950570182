.hero-block {
    padding: 5rem 3rem;
    position: relative;
    span.hero-block-title {
        font-weight: 700;
        font-size: 30px;
        transition: $defaultTransition;
    }
    span.hero-block-subtitle {
        font-size: 30px;
        font-weight: 500;
        transition: $defaultTransition;
        padding-bottom: 1rem;
    }
    svg {
        width: auto;
        height: 29px;
        transform: rotate(90deg);
        transition: $defaultTransition;
        path {
            transition: $defaultTransition;
        }
    }
    &.hero-block-reservation {
        background-color: $primary;
        border: 3px solid $primary;
        transition: $defaultTransition;
        span.hero-block-title, span.hero-block-subtitle {
            color: $bodyBackgroundColor;
            transition: $defaultTransition;
        }
        svg {
            path {
                fill: $bodyBackgroundColor;
            }
        }
        &:hover {
            background-color: $thirdColor;
            span.hero-block-title, span.hero-block-subtitle {
                color: $primary;
                transform: translateX(1rem);
            }
            svg {
                transform: translateX(1rem) rotate(90deg);
                path {
                    fill: $primary;
                }
            }
        }
    }
    &.hero-block-boutique {
        background-color: $thirdColor;
        border: 3px solid transparent;
        transition: $defaultTransition;
        span.hero-block-title, span.hero-block-subtitle {
            color: $primary;
        }
        svg {
            path {
                fill: $primary;
            }
        }
        &:hover {
            border-color: $primary;
            // span.hero-block-title, span.hero-block-subtitle {
            //     color: $thirdColor;
            //     transform: translateX(1rem);
            // }
            // svg {
            //     transform: translateX(1rem) rotate(90deg);
            //     path {
            //         fill: $thirdColor;
            //     }
            // }
        }
    }
}


.home-swiper-text-outer {
    background-size: 70%;
    background-position: 140% 200%;
    background-repeat: no-repeat;
}

.home-hero-subtitle {
    font-size: 25px;
    font-weight: 800;
}
.home-hero-text {
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 1.5rem;
}
