html, body {
    font-family: 'Montserrat', sans-serif;
    color: $secondary;
    background-color: $bodyBackgroundColor;
}
html.sr .load-hidden {
    visibility: hidden;
}
.wrap {
    overflow: hidden;
}
a {
    color: $primary;
    transition: color .35s ease-in-out;
    &:hover {
        color: $secondary;
    }
}
i {
    transition: color .35s ease-in-out;
}
h1 {
    font-size: 32px;
    font-weight: 900;
    color: $secondary;
    @include media-breakpoint-up(xxl) {
        font-size: 80px;
    }
}
h2 {
    color: $secondary;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 0;
}
h2 + a {
    margin-top: 1em;
}
h3 {
    color: $primary;
    font-weight: 700;
    font-size: 25px;
    padding-bottom: 0;
    margin-bottom: 0;
    font-family: Albra, Montserrat, Arial, sans-serif;
    @include media-breakpoint-up(md) {
        font-size: 40px;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 50px;
    }
    small {
        font-size: .7em;
    }
}
h4 {
    color: $primary;
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 0;
    margin-bottom: 0;
    text-transform: uppercase;
    @include media-breakpoint-up(md) {
        font-size: 25px;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 35px;
    }
}
h3 + h4 {
    padding-top: 0;
}
p + h3 {
    padding-top: 2.5rem;
}
p + h4 {
    padding-top: 2.5rem;
}
p {
    font-size: 18px;
}
.page-content {
    ul {
        li {
            list-style-type: none;
            position: relative;
            line-height: 1.5em;
            &::before {
                position: absolute;
                content: '\f054';
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                color: $primary;
                font-size: 12px;
                left: -15px;
                top: 1.1em;
                line-height: 1em;
                transform: translateY(-50%);
            }
        }
    }
}
.section-title {
    color: $primary;
    text-transform: none;
}
.section-subtitle, .subtitle {
    color: #b4b4b4;
    font-size: 30px;
    font-weight: 600;
}

.swiper-button-prev, .swiper-button-next {
    transition: all .35s ease-in-out;
    margin-top: 0px;
    &::after {
        transition: all .35s ease-in-out;
    }
}


.text-link-icon {
    display: inline-flex;
    align-items: center;
    gap: .75rem;
    color: $secondary;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    i {
        font-size: 16px;
        color: $primary;
        transition: all .35s ease-in-out;
    }
    &:hover {
        color: $primary;
        i {
            transform: translateX(5px);
        }
    }
}
