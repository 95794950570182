.content-block {
    position: relative;
    overflow: hidden;
    .content-block-services-picto-background {
        position: absolute;
        width: 70%;
        height: auto;
        pointer-events: none;
        z-index: 1;
        right: 0;
        top: 50%;
        transform: translate(20%, -50%);
        filter: invert(78%) sepia(42%) saturate(6794%) hue-rotate(1deg) brightness(96%) contrast(101%);
        opacity: .05;
    }
    .container {
        position: relative;
        z-index: 2;
    }
    &.content-block-bg-primary {
        background-color: $primary;
        h3, p {
            color: $bodyBackgroundColor;
        }
        .content-block-services-picto-background {
            position: absolute;
            width: 70%;
            height: auto;
            pointer-events: none;
            z-index: 1;
            left: 0;
            top: 50%;
            transform: translate(-20%, -50%);
            filter: brightness(0) invert(1);
            opacity: .15;
        }
    }
    h3 {
        text-transform: none;
        padding-bottom: 1rem;
        color: $secondary;
        @include media-breakpoint-up(lg) {
            padding-bottom: 2rem;
        }
    }
    p {
        margin-bottom: 2rem;
    }
}
