.services-pertinents {
    padding: 5rem 0;
    h3 {
        padding-bottom: 2.5rem;
    }
}
.activites-section {
    h3 {
        span {
            font-weight: 900;
        }
    }
    .swiper-button-prev, .swiper-button-next {
        position: static;
        border: 1px solid $primary;
        height: 52px;
        width: 52px;
        border-radius: 100%;
        background-color: #fff;
        &::after {
            color: $primary;
            font-size: 24px;
        }
        &:hover {
            background-color: $primary;
            &::after {
                color: #fff;
            }
        }
    }
    .activites-slider-arrows {
        display: flex;
        gap: 2rem;
    }
}
.activites-grid-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    gap: 1rem;
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(xxl) {
        transform: translateY(50%);
    }
    .activites-grid-item {
        position: relative;
        background-color: #fff;
        padding: 2rem 1rem;
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba(0,0,0,.16);
        transition: all .35s ease-in-out;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            box-shadow: 0 8px 15px rgba(0,0,0,0.3);
            opacity: 0;
            border-radius: 10px;
            transition: all .35s ease-in-out;
        }
        .activites-grid-item-picto {
            width: auto;
            margin: 0 auto;
            display: block;
            height: 90px;
            transition: all .35s ease-in-out;
        }
        .activites-grid-item-title {
            color: $secondary;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            display: block;
            position: relative;
            padding-top: 1rem;
            margin-top: 1rem;
            text-align: center;
            transition: all .35s ease-in-out;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: $primary;
                height: 3px;
                width: 42px;
                transition: all .35s ease-in-out;
            }
        }
        &:hover {
            transform: translateY(-10px);
            background-color: $primary;
            &::before {
                opacity: 1;
            }
            .activites-grid-item-picto {
                filter: brightness(0) invert(1);
            }
            .activites-grid-item-title {
                color: $bodyBackgroundColor;
                &::before {
                    background-color: $bodyBackgroundColor;
                }
            }
        }
    }
}

.activites-slider {
    background-color: #e9e9e9;
    padding: 3rem 0 3rem 0;
    position: relative;
    z-index: -1;
    @include media-breakpoint-up(md) {
        padding: 4rem 0 4rem 0;
    }
    @include media-breakpoint-up(lg) {
        padding: 5rem 0 5rem 0;
    }
    @include media-breakpoint-up(xl) {
        padding: 8rem 0 6rem 0;
    }
    @include media-breakpoint-up(xxl) {
        padding: 14rem 0 8rem 0;
    }
    img {
        border-radius: 10px;
    }
}

.services-pertinents-item {
    position: relative;
    border-bottom: 1px solid #c7c7c8;
    padding: .75rem;
    overflow: hidden;
    a.stretched-link::after {
        z-index: 500;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: $primary;
        transform: translateY(100%);
        transition: all .35s ease-in-out;
    }
    @include media-breakpoint-up(lg) {
        padding: 1.5rem;
    }
    .services-pertinents-item-content {
        position: relative;
        z-index: 55;
    }
    .services-pertinents-item-picto {
        width: auto;
        height: 70px;
    }
    .services-pertinents-item-title {
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 24px;
    }
    .services-pertinents-item-text {
        font-weight: 500;
        font-size: 19px;
    }
    &:hover {
        &::before {
            transform: translateY(0%);
        }
    }
}
