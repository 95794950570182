.return-to-top-button {
    padding: 1.25rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
        color: $primary;
        font-size: 16px;
    }
    span {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        transition: color .35s ease-in-out;
        text-transform: uppercase;
    }
    &:hover {
        i {
            color: $secondary;
        }
        span {
            color: $primary;
        }
    }
}
.discover-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
        color: $primary;
        font-size: 16px;
    }
    span {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        transition: color .35s ease-in-out;
    }
    &:hover {
        i {
            color: $secondary;
        }
        span {
            color: $primary;
        }
    }
}

#footer {
    .footer-top {
        .footer-top-inner {
            padding: 0 0 2rem 0;
            .footer-logo-link {
                display: inline-flex;
                padding: 0 2rem;
                background-color: $bodyBackgroundColor;
                position: relative;
                z-index: 5;
                img, svg {
                    width: 340px;
                    height: auto;
                }
            }
        }
    }
}
.footer-top-inner {
    & > div {
        position: relative;
        &::after, &::before {
            content: '';
            position: absolute;
            height: 2px;
            background-color: $primary;
            top: 65px;
            width: 100%;
        }
        &::after {

        }
        &::before {

        }
    }
}
.footer-menu {
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem;
        list-style-type: none;
        li {
            position: relative;
            a {
                text-decoration: none;
                color: $primary;
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 700;
                &:hover {
                    color: $secondary;
                }
            }
        }
        li:not(:last-child)::after {
            content: '';
            position: absolute;
            top: 50%;
            height: 6px;
            width: 6px;
            background-color: $primary;
            right: -15px;
            transform: translateX(50%) translateY(-50%);
        }
    }
}
.footer-address {
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    text-transform: uppercase;
}
.footer-tel {
    margin-bottom: 10px;
    font-weight: 700;
    text-decoration: none;
    font-size: 25px;
    font-weight: 700;
}
.footer-boutique-title {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: $secondary;
    margin-bottom: 0;
    padding-bottom: 3rem;
    img {
        display: block;
        margin: 0 auto;
    }
}
.footer-bottom {
    margin-top: 2rem;
    background-color: #e9e9e9;
    padding: 2rem 0;
    @include media-breakpoint-up(lg) {
        padding: 3rem 0;
    }
    .footer-bottom-title {
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 900;
        position: relative;
        margin-bottom: 1.5rem;
        padding-bottom: 1rem;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 42px;
            height: 3px;
            background-color: $primary;
        }
    }
    .footer-bottom-menu {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        columns: 3;
        li {
            padding-bottom: .25rem;
            a {
                color: $secondary;
                font-size: 15px;
                font-weight: 900;
                text-decoration: none;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

.footer-bottom-mentions {
    background-color: $primary;
    padding: .75rem 0;
    color: $bodyBackgroundColor;
    font-size: 13px;
    a {
        color: $bodyBackgroundColor;
        text-decoration: none;
        &:hover {
            color: $secondary;
        }
    }
}

// Footer cabinet
.footer-boutique {
    display: flex;
    flex-direction: column;
    span.footer-boutique-ville {
        font-weight: bold;
        font-size: 15px;
        text-transform: uppercase;
        position: relative;
        margin-bottom: .75rem;
    }
    address.footer-boutique-address {
        font-size: 14px;
        font-weight: 500;
    }

}
.footer-menu {
    margin-top: 1.75rem;
}

ul.footer-menu-social {
    padding-left: 0;
    margin-top: 15px;
    margin-bottom: 0;
    list-style-type: none;
    // position: absolute;
    // top: 50%;
    // right: 0;
    display: flex;
    gap: .5rem;
    justify-content: center;
    align-items: center;

    li {
        a {
            i {
                color: $secondary;
                font-size: 24px;
            }
            &:hover {
                i {
                    color: $primary;
                }
            }
        }
    }
}

