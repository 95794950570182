.flip-flaps {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-size: 60% auto;
    background-repeat: no-repeat;
    background-position: 80% 30%;
    @include media-breakpoint-up(xxl) {
        padding-bottom: 8rem;
    }
    .order-2 .flip-flap-img-container::after {
        right: auto;
        left: 0;
        transform: translateX(-50%);
    }
    h3, h4 {
        padding-bottom: 0;
    }
    h4 {
        color: $secondary;
        text-transform: none;
        font-weight: 700;
        font-size: 35px;
        padding-bottom: 3rem;
        font-family: Albra, Montserrat, Arial, sans-serif;
    }
    .flip-flap-content {
        padding-top: 1rem;
        ul {
            overflow: hidden;
            padding: 0;
            li {
                position: relative;
                margin-top: 6px;
                padding-left: 20px;
                list-style-type: none;
                // font-weight: bold;
                // text-transform: uppercase;
                &::before {
                    position: absolute;
                    top: 6px;
                    left: 0;
                    width: 10px;
                    height: 10px;
                    border: 3px solid $primary;
                    border-bottom: 0;
                    border-left: 0;
                    content: '';
                    transform: rotate(45deg);
                }
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
    & > div:not(:last-child) {
        padding-bottom: 6rem;
        @include media-breakpoint-up(md) {
            padding-bottom: 8rem;
        }
    }
    .icon-pate svg {
        width: 190px;
        height: 190px;
    }
}


.flip-flap-img-container {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        height: 25px;
        background-color: $primary;
        width: 70px;
        right: 0;
        top: 4rem;
        z-index: 5;
        transform: translateX(50%);
    }
    img {
        aspect-ratio: 1/1;
        object-fit: cover;
        width: 100%;
    }
}

.pictos-row-outer {
    img, svg {
        height: auto;
        width: 52px;
    }
}
