/**
 * GENERAL
 * MEDIA QUERIES
 */





/* GENERAL
---------------------------------------- */
.editor  {
	font-size: 20px;
	line-height: 1.5;
	color: $secondary;
	& > *:first-child {
		margin-top: 0 !important;
	}
	& > *:last-child {
		margin-bottom: 0 !important;
	}


	/* Titres */
	h1, h2, h3, h4, h5, h6 {
		margin-top: 1.3em;
		margin-bottom: 15px;
		padding: 0;
		font-family: Albra, Montserrat, Arial, sans-serif;
		line-height: 1.1;
		font-weight: bold;
		color: $primary;
		text-transform: none;
	}
	h1 {
		font-size: 60px;
	}
	h2 {
		font-size: 24px;
		font-weight: 500;
	}
	h3 {
		font-size: 45px;
	}
	h4 {
		font-size: 35px;
	}
	h5, h6 {
		font-size: 20px;
	}


	/* Highlighted */
	.highlighted {
		position: relative;
		padding-left: 39px;
		&::before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			display: block;
			width: 1px;
			background-color: $primary;
			content: '';
		}
	}


	/* Contenu */
	strong, b {
		font-weight: bold;
	}

	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}

	p, ul, ol {
		margin-bottom: 15px;
		font-size: inherit;
	}
	ul {
		margin-top: 35px;
		margin-bottom: 15px;
		padding-left: rem(38px);
		border-left: 2px solid $primary;
		li {
			margin-top: 25px;
			&::before {
				display: none;
			}
			&:first-child {
				margin-top: 0;
			}
			> *:first-child {
				margin-top: 0;
			}
			> *:last-child {
				margin-bottom: 0;
			}
		}
	}


	/* Images */
	img, figure {
		display: block;
		height: auto;
		margin: 35px;
		border: 0;
		&:first-child {
			margin-top: 7px;
		}
		&.alignleft, &.alignright {
			max-width: 50%;
		}
		&.alignleft {
			float: left;
			margin-left: 0;
		}
		&.alignright {
			float: right;
			margin-right: 0;
		}
		&.aligncenter {
			margin-right: auto;
			margin-left: auto;
			clear: both;
		}
	}
	figure {
		margin-top: 7px;
		img {
			margin: 0 !important;
		}
		figcaption {
			margin-top: 5px;
			opacity: 0.5;
			font-size: 0.9em;
			line-height: 1.25;
			font-style: italic;
		}
	}
}





/* MEDIA QUERIES
---------------------------------------- */
@media only screen and (max-width: 1200px) {
}



@media only screen and (max-width: 1024px) {
}



@media only screen and (max-width: 960px) {
	.editor h3 {
		font-size: 35px;
	}
}



@media only screen and (max-width: 600px) {
	.editor {
		h1 {
			font-size: 42px;
		}
		h3 {
			font-size: 30px;
		}
		img, figure {
			&, &.alignleft, &.alignright, &.aligncenter {
				display: block;
				max-width: 100%;
				margin: 30px auto !important;
				float: none;
			}
		}
		figure img {
			margin: 0 !important;
		}
	}
}



@media only screen and (max-width: 480px) {
}
