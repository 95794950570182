@import "common/variables";
@import "autoload/bootstrap";

@import "components/navigation";
@import "components/hero";
@import "components/buttons";
@import "components/editor";
@import "components/forms";
@import "components/flip-flaps";
@import "components/footer";
@import "components/content-block";
@import "components/cookies";
@import "components/no-cookies";

@import "common/fonts";
@import "common/typography";

@import "blocks/etapes";
@import "blocks/citation";
@import "blocks/outils";
@import "blocks/actualites";
@import "blocks/services";
@import "blocks/presentation";
@import "blocks/activites";
@import "blocks/tabs";
@import "blocks/list";
@import "blocks/text-image";

@import "layouts/archive-news";
@import "layouts/page-header";
@import "layouts/home";
@import "layouts/pages";
@import "layouts/not-found";
@import "layouts/contact";
@import "layouts/boutique";
