
.services-item {
    position: relative;
    overflow: hidden;
    a.stretched-link::after {
        z-index: 5;
    }
    .services-item-img {
        aspect-ratio: 1/1;
        width: 100%;
        object-fit: cover;
        transition: $defaultTransition;
    }
    .services-item-content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 2rem;
        z-index: 2;
        .services-item-content-picto {
            width: auto;
            height: 62px;
            transition: $defaultTransition;
        }
        .services-item-content-title {
            color: $bodyBackgroundColor;
            margin-bottom: 0;
            padding: .5rem 0;
            font-size: 29px;
            font-weight: 500;
            transition: $defaultTransition;
        }
        .services-item-content-text {
            color: $bodyBackgroundColor;
            font-size: 17px;
            font-weight: 500;
            transition: $defaultTransition;
        }
    }
    &.services-item-odd {
        .services-item-content-picto {
            filter: brightness(0) invert(1);
        }
        .services-item-content-title {
            color: $bodyBackgroundColor;
        }
        .services-item-content-text {

        }
    }
    &.services-item-even {
        .services-item-content-picto {
            filter: invert(78%) sepia(42%) saturate(6794%) hue-rotate(1deg) brightness(96%) contrast(101%);
        }
        .services-item-content-title {
            color: $primary;
        }
        .services-item-content-text {

        }
    }
    &::before {
        content: '';
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%);
    }
    &:hover {
        .services-item-img {
            transform: scale(1.1);
        }
    }
}

.services-section-title-outer {

}
.services-pertinents {
    position: relative;
    img.services-pertinents-graphisme {
        position: absolute;
        top: 15%;
        right: 5%;
        background-size: contain;
        pointer-events: none;
        z-index: -1;
        display: none;
        @include media-breakpoint-up(xl) {
            display: block;
        }
    }
}
// Page Services
.services-section-ancres {
    & > div > div:not(:last-child) {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(50%, -50%);
            height: 8px;
            width: 8px;
            background-color: $secondary;
            border-radius: 100%;
        }
    }
    .services-section-ancres-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        img {
            width: auto;
            height: 110px;
            transition: $defaultTransition;
        }
        .services-section-ancres-title {
            padding-top: 2rem;
            transition: $defaultTransition;
            font-size: 26px;
            font-weight: 700;
            text-align: center;
        }
        &:hover {
            img {
                filter: invert(78%) sepia(42%) saturate(6794%) hue-rotate(1deg) brightness(96%) contrast(101%);
            }
            .services-section-ancres-title {
                color: $primary;
            }
        }
    }
}

.illustration-citation {
    width: calc(100% + 90px);
    max-width: initial;
    height: auto;
    transform: translateX(-90px) translateY(-30px);
}
