@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Albra';
    src: url('../fonts/albra/AlbraBold.eot');
    src: url('../fonts/albra/AlbraBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/albra/AlbraBold.woff2') format('woff2'),
    url('../fonts/albra/AlbraBold.woff') format('woff'),
    url('../fonts/albra/AlbraBold.ttf') format('truetype'),
    url('../fonts/albra/AlbraBold.svg#AlbraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albra';
    src: url('../fonts/albra/AlbraLight.eot');
    src: url('../fonts/albra/AlbraLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/albra/AlbraLight.woff2') format('woff2'),
    url('../fonts/albra/AlbraLight.woff') format('woff'),
    url('../fonts/albra/AlbraLight.ttf') format('truetype'),
    url('../fonts/albra/AlbraLight.svg#AlbraLight') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albra';
    src: url('../fonts/albra/AlbraBlack.eot');
    src: url('../fonts/albra/AlbraBlack.eot?#iefix') format('embedded-opentype'),
    url('../fonts/albra/AlbraBlack.woff2') format('woff2'),
    url('../fonts/albra/AlbraBlack.woff') format('woff'),
    url('../fonts/albra/AlbraBlack.ttf') format('truetype'),
    url('../fonts/albra/AlbraBlack.svg#AlbraBlack') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albra';
    src: url('../fonts/albra/AlbraMedium.eot');
    src: url('../fonts/albra/AlbraMedium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/albra/AlbraMedium.woff2') format('woff2'),
    url('../fonts/albra/AlbraMedium.woff') format('woff'),
    url('../fonts/albra/AlbraMedium.ttf') format('truetype'),
    url('../fonts/albra/AlbraMedium.svg#AlbraMedium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albra Semi';
    src: url('../fonts/albra/AlbraSemi.eot');
    src: url('../fonts/albra/AlbraSemi.eot?#iefix') format('embedded-opentype'),
    url('../fonts/albra/AlbraSemi.woff2') format('woff2'),
    url('../fonts/albra/AlbraSemi.woff') format('woff'),
    url('../fonts/albra/AlbraSemi.ttf') format('truetype'),
    url('../fonts/albra/AlbraSemi.svg#AlbraSemi') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albra';
    src: url('../fonts/albra/AlbraRegular.eot');
    src: url('../fonts/albra/AlbraRegular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/albra/AlbraRegular.woff2') format('woff2'),
    url('../fonts/albra/AlbraRegular.woff') format('woff'),
    url('../fonts/albra/AlbraRegular.ttf') format('truetype'),
    url('../fonts/albra/AlbraRegular.svg#AlbraRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albra Text Semi';
    src: url('../fonts/albra/AlbraTextSemi.eot');
    src: url('../fonts/albra/AlbraTextSemi.eot?#iefix') format('embedded-opentype'),
    url('../fonts/albra/AlbraTextSemi.woff2') format('woff2'),
    url('../fonts/albra/AlbraTextSemi.woff') format('woff'),
    url('../fonts/albra/AlbraTextSemi.ttf') format('truetype'),
    url('../fonts/albra/AlbraTextSemi.svg#AlbraTextSemi') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albra Text';
    src: url('../fonts/albra/AlbraTextMedium.eot');
    src: url('../fonts/albra/AlbraTextMedium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/albra/AlbraTextMedium.woff2') format('woff2'),
    url('../fonts/albra/AlbraTextMedium.woff') format('woff'),
    url('../fonts/albra/AlbraTextMedium.ttf') format('truetype'),
    url('../fonts/albra/AlbraTextMedium.svg#AlbraTextMedium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albra Text';
    src: url('../fonts/albra/AlbraTextLight.eot');
    src: url('../fonts/albra/AlbraTextLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/albra/AlbraTextLight.woff2') format('woff2'),
    url('../fonts/albra/AlbraTextLight.woff') format('woff'),
    url('../fonts/albra/AlbraTextLight.ttf') format('truetype'),
    url('../fonts/albra/AlbraTextLight.svg#AlbraTextLight') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albra Text';
    src: url('../fonts/albra/AlbraTextBlack.eot');
    src: url('../fonts/albra/AlbraTextBlack.eot?#iefix') format('embedded-opentype'),
    url('../fonts/albra/AlbraTextBlack.woff2') format('woff2'),
    url('../fonts/albra/AlbraTextBlack.woff') format('woff'),
    url('../fonts/albra/AlbraTextBlack.ttf') format('truetype'),
    url('../fonts/albra/AlbraTextBlack.svg#AlbraTextBlack') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albra Text';
    src: url('../fonts/albra/AlbraTextRegular.eot');
    src: url('../fonts/albra/AlbraTextRegular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/albra/AlbraTextRegular.woff2') format('woff2'),
    url('../fonts/albra/AlbraTextRegular.woff') format('woff'),
    url('../fonts/albra/AlbraTextRegular.ttf') format('truetype'),
    url('../fonts/albra/AlbraTextRegular.svg#AlbraTextRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albra Text';
    src: url('../fonts/albra/AlbraTextBold.eot');
    src: url('../fonts/albra/AlbraTextBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/albra/AlbraTextBold.woff2') format('woff2'),
    url('../fonts/albra/AlbraTextBold.woff') format('woff'),
    url('../fonts/albra/AlbraTextBold.ttf') format('truetype'),
    url('../fonts/albra/AlbraTextBold.svg#AlbraTextBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

