.home-outils-section {
    background-image: linear-gradient(to top, $primary 50%, #fff 50%);
    position: relative;
    img.home-outils-section-graphisme {
        position: absolute;
        top: 10%;
        left: 10%;
        background-size: contain;
        pointer-events: none;
        display: none;
        @include media-breakpoint-up(xl) {
            display: block;
        }
    }
}
.home-outils-item {
    background-color: #fff;
    border-radius: 10px;
    padding: 1.5rem 1rem 1rem 1rem;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    position: relative;
    transition: all .35s ease-in-out;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 8px 15px rgba(0,0,0,0.3);
        opacity: 0;
        border-radius: 10px;
        transition: all .35s ease-in-out;
    }
    .home-outils-item-picto {
        width: auto;
        height: 70px;
        margin: 0 auto;
        display: block;
        transition: all .35s ease-in-out;
    }
    .home-outils-item-title {
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
        padding: 1.25rem 0 1rem 0;
        transition: all .35s ease-in-out;
    }
    .home-outils-item-text {
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        transition: all .35s ease-in-out;
    }
    &:hover {
        transform: translateY(-10px);
        background-color: $secondary;
        &::before {
            opacity: 1;
        }
        .home-outils-item-picto {
            filter: brightness(100) invert(1);
        }
        .home-outils-item-title, .home-outils-item-text {
            color: $bodyBackgroundColor;
        }
    }
}

.outils-cta-text {
    text-align: center;
    font-weight: 700;
}

.outils-section-primary {
    background-color: $primary;
    margin: 3rem 0;
    .outils-section-title, p {
        color: $bodyBackgroundColor;
    }
    .outils-section-fonctionnalites-grid {
        .outils-section-fonctionnalites-grid-item {
            .outils-section-fonctionnalites-grid-item-title {
                color: $bodyBackgroundColor;
            }
            .outils-section-fonctionnalites-grid-item-text {
                color: $bodyBackgroundColor;
            }
            .outils-section-fonctionnalites-grid-item-picto {
                filter: brightness(0) invert(1);
            }
        }
    }
}

hr.outils-separator {
    background-color: #f39200;
}

.outils-citation {
    position: relative;
    .outils-citation-quote-1, .outils-citation-quote-2 {
        position: absolute;
        pointer-events: none;
    }
    .outils-citation-quote-1 {
        left: 0;
        top: 0;
        transform: translate(-110%, -60%);
    }
    .outils-citation-quote-2 {
        display: none;
        right: 0;
        bottom: 0;
        transform: translate(110%, 60%);
    }
}

.outils-section {
    .illustration-citation {
        transform: translateX(-160px) translateY(-120px);
    }
}

.outils-section-text {
    padding-bottom: 1rem;
    @include media-breakpoint-up(lg) {
        padding-bottom: 2.5rem;
    }
}
