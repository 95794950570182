
.home-swiper-img {
    aspect-ratio: 1/.85;
    object-fit: cover;
    object-position: center;
    width: 100%;
}
.home {
    .modules-wrapper {
        margin-top: 0;
        &::before {
            display: none;
        }
    }
    .actualites-section {
        padding-top: 1rem;
        .section-title {
            font-size: 45px;
            @include media-breakpoint-up(md) {
                font-size: 60px;
            }
            @include media-breakpoint-up(xxl) {
                font-size: 75px;
            }
        }
    }
}
.hero {
    .home-swiper-text {
        .swiper-slide {
            overflow: hidden;
        }
    }
    .swiper-button-prev {
        left: 1.5rem;
    }
    .swiper-button-next {
        right: 1.5rem;
    }
    .swiper-button-prev,
    .swiper-button-next {
        display: none;
        opacity: 0;
        transform: translateY(-50%);
        &::after {
            color: #fff;
            font-size: 28px;
        }
        &:hover {
            &::after {
                color: $primary;
            }
        }
    }
}
.home-swiper {
    width: 100%;
    &.swiper-initialized {
        .swiper-button-prev, .swiper-button-next {
            display: block;
        }
    }
}
.home-text-container-content {
    padding-left: 3rem;
}
.home-text-container {
    padding: 1rem 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    @include media-breakpoint-up(lg) {
        padding: 3rem 0;
    }
    @include media-breakpoint-up(xl) {
        padding: 6rem 0;
    }
    .title-wrapper {
        width: 100%;
        max-width: 790px;
        margin-right: auto;
        margin-left: auto;
    }
    h3 {
        max-width: 100%;
        font-size: 45px;
        @include media-breakpoint-up(md) {
            max-width: 495px;
            font-size: 60px;
        }
        @include media-breakpoint-up(xxl) {
            max-width: 555px;
            font-size: 75px;
        }
    }
    .home-text-outer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // padding-left: 3rem;
        margin-top: 4rem;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 20px;
            right: calc(100% + 45px);
            height: 1px;
            background-color: $primary;
            width: 500px;
        }
    }
}
.home-swiper-outer {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        height: 25px;
        background-color: $primary;
        width: 70px;
        top: 15%;
        left: 0;
        z-index: 2;
        transform: translateX(-50%);
    }
    @include media-breakpoint-up(md) {
        &::before {
            top: 27%;
        }

    }
    @include media-breakpoint-up(xxl) {
        &::before {
            top: 32%;
        }
    }
    &:hover {
        .swiper-button-prev, .swiper-button-next {
            opacity: 1;
        }
    }
}
.nos-produits {
    padding: 5rem 0;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
        padding: 7rem 0;
    }
    @include media-breakpoint-up(xl) {
        padding: 9rem 0;
    }
}
.nos-produits-title {
    text-align: center;
    font-size: 45px;
    text-transform: none;
    padding-bottom: 3rem;
}
.nos-produits-item {
    position: relative;
    background-color: $primary;
    .nos-produits-item-img {
        object-fit: cover;
        aspect-ratio: 1/.9;
        object-position: center;
        width: 100%;
        transition: $defaultTransition;
    }
    .nos-produits-item-title {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 136px;
        color: $bodyBackgroundColor;
        background-color: $primary;
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
        padding: 1rem;
        transition: $defaultTransition;
        .home & {
            min-height: 92px;
        }
    }
    &:hover {
        .nos-produits-item-img {
            opacity: .25;
        }
        .nos-produits-item-title {
            background-color: $secondary;
        }
    }
}

.wysiwyg-img {
    padding: 5rem 0;
    // background: linear-gradient(to bottom, $bodyBackgroundColor 50%, $primary 50%);
    h4 {
        color: $thirdColor;
        font-family: Albra, Montserrat, Arial, sans-serif;
        margin-bottom: 2rem;
    }
    .wysiwyg-img-image-outer {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            height: 25px;
            background-color: $primary;
            width: 70px;
            left: 0;
            top: 4rem;
            z-index: 10;
            transform: translateX(-50%);
        }
    }
    img {
        aspect-ratio: 1/1;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
    }
    blockquote {
        color: $bodyBackgroundColor;
        font-size: 45px;
        font-weight: 700;
        line-height: 1.25;
        font-family: Albra, Montserrat, Arial, sans-serif;
    }

    .wysiwyg-img-title-outer {
        max-width: 100%;
        padding-top: 0;
        h3 {
            font-size: 45px;
        }
        @include media-breakpoint-up(md) {
            max-width: 310px;
            h3 {
                font-size: 60px;
            }
        }
        @include media-breakpoint-up(xxl) {
            max-width: 390px;
            h3 {
                font-size: 75px;
            }
        }
    }
    .wysiwyg-text {
        color: $bodyBackgroundColor;
        padding-bottom: 2rem;
    }
}
.wysiwyg-text-outer {
    padding: 0 3rem 0 1rem;
    .btn {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}
.wysiwyg-img-title-outer {
    padding: 3rem 0 4rem 0;
}
.wysiwyg-img-second-section {
    position: relative;
    background-color: $primary;
    padding: 4rem 0 7rem 0;
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        opacity: .1;
        background: no-repeat url('../images/A.svg') center;
        background-size: contain;
        content: '';
    }
    @media only screen and (max-width:768px) {
        &::before {
            display: none;
        }
    }
    @media only screen and (max-width:992px) {
        padding: 7rem 0 7rem 0;
    }
    .container {
        position: relative;
    }
}
.hero-block-boutique {
    position: relative;
    .hero-block-boutique-svg-outer {
        position: absolute;
        right: 10%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        img, svg {
            width: 150px;
            height: auto;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 1px;
            height: 100px;
            background-color: $primary;
            transform: translateY(calc(100% + 2rem)) translateX(-50%);
        }
        &::before {
            content: "";
            position: absolute;
            top: -100%;
            left: 50%;
            width: 1px;
            height: 100px;
            background-color: $primary;
            transform: translateY(calc(0% + 2rem)) translateX(-50%);
        }
    }
    &:hover {
        .hero-block-boutique-svg-outer {
            // img {
            //      filter: invert(90%) sepia(15%) saturate(432%) hue-rotate(328deg) brightness(110%) contrast(98%);
            // }
        }
    }
    @media only screen and (max-width: 1599px) {
        .hero-block-boutique-svg-outer {
            right: -74px;
        }
    }
    @media only screen and (max-width: 1400px) {
        .hero-block-boutique-svg-outer {
            right: 5px;
        }
    }
    @media only screen and (max-width: 1325px) {
        .hero-block-boutique-svg-outer {
            top: 10px;
            right: 20px;
            transform: translateY(0);
            &::before, &::after {
                display: none;
            }
            img {
                width: 80px;
            }
        }
    }
}
