


a.cookies-button {
  position: fixed;
  z-index: 999;
  background-color: #b91f27;
  width: 60px;
  height: 60px;
  bottom: 2rem;
  left: 2rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 25%);
  //opacity: 0;
  //visibility: hidden;
  transform: translateY(10px);
  transition: $defaultTransition;
  svg {
    fill: #fff;
    width: auto;
    height: 35px;
  }
}
a.cookies-button.is-visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}
a.cookies-button:hover {
  background-color: $secondary;
  transform: translateY(-5px);
}
.cc_div {
  font-family: 'Montserrat', sans-serif;
  .c-bn {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
#cm, #s-bl .act .b-acc, #s-inr, .cc_div .b-tl, .cc_div .c-bl {
  border-radius: 0;
}
#c-ttl {
  font-size: 1.75em;
}
.cc_div .c-bn {
  border-radius: 0;
}
:root {
  --cc-bg: #fdfbf4;
  --cc-text: #1d1d1b;
  --cc-btn-primary-bg: #b91f27;
  --cc-btn-primary-text: var(--cc-bg);
  --cc-btn-primary-hover-bg: #1d1d1b;
  --cc-btn-secondary-bg: #eaeff2;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #d8e0e6;
  --cc-toggle-bg-off: #919ea6;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #d5dee2;
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #f0f4f7;
  --cc-cookie-category-block-bg-hover: #e9eff4;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e9edf2;
  --cc-overlay-bg: rgba(4, 6, 8, .85);
  --cc-webkit-scrollbar-bg: #cfd5db;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}
