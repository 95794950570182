.form-control {
    border-radius: 0px;
}
.gform_wrapper.gravity-theme input[type=color], .gform_wrapper.gravity-theme input[type=date], .gform_wrapper.gravity-theme input[type=datetime-local], .gform_wrapper.gravity-theme input[type=datetime], .gform_wrapper.gravity-theme input[type=email], .gform_wrapper.gravity-theme input[type=month], .gform_wrapper.gravity-theme input[type=number], .gform_wrapper.gravity-theme input[type=password], .gform_wrapper.gravity-theme input[type=search], .gform_wrapper.gravity-theme input[type=tel], .gform_wrapper.gravity-theme input[type=text], .gform_wrapper.gravity-theme input[type=time], .gform_wrapper.gravity-theme input[type=url], .gform_wrapper.gravity-theme input[type=week], .gform_wrapper.gravity-theme select, .gform_wrapper.gravity-theme textarea {
    @extend .form-control;
    border-color: #c7c7c8;
    border-radius: 0px !important;
    background-color: #fcf7e7;
    &:focus {
      background-color: $bodyBackgroundColor;
    }
  }
  .gfield_label {
    @extend .form-label;
  }
.gform_wrapper.gravity-theme .gfield_label {
    padding-bottom: 10px !important;
    margin-bottom: 0 !important;
    color: $secondary !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
  }
  .gform_wrapper .gfield_required {
    font-size: 16px !important;
  }
  .gform_wrapper input[type=submit] {
    @extend .btn, .btn-primary;
  }

  .gform_wrapper input[type=submit] {

  }

  .search-field {
    @extend .form-control;
    border-radius: 0;
    background-color: #fcf7e7;
  }
  .search-submit {
    @extend .btn, .btn-primary;
  }

  .search-form {
    gap: 1rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      max-width: 60%;
    }
    label {
      display: flex;
      align-items: center;
      flex-grow: 1;
      gap: 1rem;
    }
  }
