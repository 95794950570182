.module-text-image {
    .wysiwyg-text + .hstack {
        flex-wrap: wrap;
    }
    @media only screen and (max-width: 767px) {
        .wysiwyg-img-title-outer {
            padding-top: 0;
            padding-bottom: 0;
        }
        img.w-100 {
            position: relative;
        }
        .wysiwyg-img-second-section {
            margin-top: -6rem;
            padding-top: 7rem;
            .wysiwyg-text-outer {
                padding-right: 0;
                padding-left: 0;
            }
            .hstack a {
                width: 100%;
            }
            blockquote {
                font-size: 30px;
            }
        }
    }
}
