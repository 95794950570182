.etapes {
    padding-top: 3rem;
    padding-bottom: 3rem;
    @include media-breakpoint-up(lg) {
        padding-top: 5rem;
    }
    .flip-flap-img-container {
        position: relative;
        img {
            overflow: hidden;
            border-radius: 10px;
        }
        .etape-number {
            position: absolute;
            bottom: 0;
            right: 3rem;
            font-size: 200px;
            transform: translateY(50%);
            font-weight: 700;
        }
    }
}
.etapes-title {
    text-transform: none;
    color: $secondary;
}