.actualites-section {
    padding: 4rem 0 115px 0;
    @include media-breakpoint-up(md) {
        padding-top: 5rem;
    }
    @include media-breakpoint-up(lg) {
        padding-top: 6rem;
    }
    @include media-breakpoint-up(xl) {
        padding-top: 7rem;
    }
    @include media-breakpoint-up(xxl) {
        padding-top: 225px;
    }
}
// Slider
.actualites-slider {
    position: relative;
    .swiper-button-next, .swiper-button-prev {
        transform: translateY(-50%);
        top: 40%;
        &::after {
            color: $primary;
            font-size: 22px;
            transition: $defaultTransition;
        }
        &:hover {
            &::after {
                color: $secondary;
            }
        }
    }
    @include media-breakpoint-up(xxl) {
        .swiper-button-next {
            right: -5rem;
        }
        .swiper-button-prev {
            left: -5rem;
        }
    }
}
// Items
.actualites-item {
    &:hover {
        .actualites-item-thumbnail-container {
            .actualites-item-thumbnail {
                opacity: .2;
            }
        }
        .actualites-item-content {
            .actualites-item-title {
                background-color: $secondary;
            }
        }
    }
}
.actualites-item-thumbnail-container {
    overflow: hidden;
    background-color: $primary;
    .actualites-item-thumbnail {
        aspect-ratio: 1/1;
        object-fit: cover;
        transition: $defaultTransition;
    }
}

.actualites-item-content {
    padding: 0 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .actualites-item-title {
        color: $bodyBackgroundColor;
        display: inline-block;
        padding: .75rem;
        background-color: $primary;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 700;
        transform: translateY(-50%);
        transition: $defaultTransition;
    }
    a {
        display: inline-block;
        margin-top: 14px;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
        color: $primary;
    }
}
.actualites-item {
    position: relative;
}
